html,
body {
    margin: 0;
}

#App {
    font-family: sans-serif;
}

#page-wrap {
    text-align: center;
}
